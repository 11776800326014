import React from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Space, Form, Row, Col, Input, DatePicker, Radio, Select, Button, Upload, Image, Empty, Tag, message } from 'antd';
import type { GetProp, UploadProps } from 'antd';
import { SelectGroupModal } from '../selectGroup';
import type AnchorEditModel from './model';
import { FullModal, singleUploadObs } from '../../../../utils';
import { observer } from 'mobx-react';
import styles from './index.less';
import getImgUrl from '../../../../utils/firstFrame';
import compressImgOrVideo from '../../../../utils/compress';
import { v4 as uuidv4 } from 'uuid';

@observer
export default class AnchorEditor extends React.Component<{ store: AnchorEditModel }> {
  render() {
    const { visible, loadingSave, opusListImage, opusListVideo, setDeleteOpusList, setAddOpusList, type, setLoadingVideo, loadingVideo, loading, setGroupInfo, selectGroupStore, groupInfo, onSave, onCancel, setRef } =
      this?.props?.store || {};
    const uploadImageButton = (
      <button
        style={{
          border: 0,
          background: 'none',
        }}
        type="button"
      >
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>添加图片</div>
      </button>
    );

    const uploadVideoButton = (
      <button
        style={{
          border: 0,
          background: 'none',
        }}
        type="button"
      >
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>添加视频</div>
      </button>
    );

    // 身高、体重验证规则
    const heightAndWeightRules = [
      {
        required: true,
        pattern: /^\d+(\.\d+)?$/,
        message: '请输入数字',
      },
    ];

    return (
      <FullModal
        onCancel={onCancel}
        visible={visible}
        operation={
          <div className={styles.btnBox}>
            <Button
              loading={loadingSave}
              type="primary"
              onClick={onSave}
            >
              保存
            </Button>
          </div>
        }
        title={type === 'add' ? '新增主播' : '编辑主播'}
      >
        <div className={styles.container}>
          <Card
            title="主播信息"
            bordered={false}
            style={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <Form
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              ref={setRef}
            >
              <Row>
                <Col span={4}>
                  <Form.Item
                    label="姓名"
                    name="name"
                    validateTrigger="onBlur"
                  >
                    <Input
                      autoComplete="off"
                      placeholder="请输入姓名"
                      maxLength={8}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="出生日期"
                    name="birthday"
                    validateTrigger="onBlur"
                  >
                    <DatePicker
                      format="YYYY-MM-DD"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="性别"
                    name="sex"
                    initialValue={1}
                    validateTrigger="onBlur"
                  >
                    <Radio.Group>
                      <Radio value={1}>男</Radio>
                      <Radio value={0}>女</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="身高"
                    name="height"
                    validateTrigger="onBlur"
                  >
                    <Input
                      autoComplete="off"
                      addonAfter="CM"
                      placeholder="请输入身高"
                      maxLength={5}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="体重"
                    name="weight"
                    validateTrigger="onBlur"
                  >
                    <Input
                      autoComplete="off"
                      addonAfter="KG"
                      placeholder="请输入体重"
                      maxLength={5}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <Form.Item
                    label="工作年限"
                    name="seniority"
                    validateTrigger="onBlur"
                  >
                    <Select
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      options={Array.from({ length: 100 }, (v, i) => ({
                        value: i,
                        label: i,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="手机号"
                    name="mobile"
                    validateTrigger="onBlur"
                  >
                    <Input
                      autoComplete="off"
                      placeholder="请输入手机号"
                      maxLength={11}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="微信号"
                    name="wechatCode"
                    validateTrigger="onBlur"
                  >
                    <Input
                      autoComplete="off"
                      placeholder="请输入微信号"
                      maxLength={50}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="擅长领域"
                    name="skilledField"
                    validateTrigger="onBlur"
                  >
                    <Input
                      autoComplete="off"
                      placeholder="请输入擅长领域"
                      maxLength={200}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="单场最高GMV"
                    name="highestGmv"
                    validateTrigger="onBlur"
                  >
                    <Input
                      autoComplete="off"
                      addonAfter="元"
                      placeholder="请输入单场最高GMV"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>

          <Card
            title="群组信息"
            extra={
              <Button
                type="primary"
                onClick={selectGroupStore.onShow}
              >
                添加
              </Button>
            }
            bordered={false}
            style={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            {groupInfo?.length ? (
              <Space className={styles.groupInfo}>
                {groupInfo.map((item, index) => (
                  <Tag
                    onClose={(e) => {
                      e.preventDefault();
                      setGroupInfo(item.anchorGroupId);
                    }}
                    closable
                    key={item.anchorGroupId}
                  >
                    {item.anchorGroupName}（{item.anchorGroupNickName}）
                  </Tag>
                ))}
              </Space>
            ) : (
              <div className={styles.empty}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </Card>

          <Card
            title="作品信息"
            bordered={false}
            style={{ width: '100%' }}
          >
            <Row>
              <Col span={12}>
                <Upload
                  listType="picture-card"
                  fileList={opusListImage}
                  beforeUpload={beforeUpload}
                  maxCount={8}
                  // multiple
                  customRequest={async (i: any) => {
                    const unit = i.file.name.split(',');
                    const file: any = await compressImgOrVideo(i.file);
                    if (file === false) {
                      return;
                    }

                    const url = await singleUploadObs(file, `anchorManagement/avatar/image/${i.file.uid}${unit[unit.length - 1]}`);
                    setAddOpusList(url, 0);
                  }}
                  onRemove={(values) => {
                    setDeleteOpusList(values.url, 0);
                  }}
                >
                  {opusListImage && opusListImage.length >= 8 ? null : (
                    <button
                      style={{
                        border: 0,
                        background: 'none',
                      }}
                      type="button"
                    >
                      {loading ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{ marginTop: 8 }}>添加图片</div>
                    </button>
                  )}
                </Upload>
                <span>支持 JPG 、 PNG，大小不超过5M</span>
              </Col>
              <Col span={12}>
                <Upload
                  listType="picture-card"
                  fileList={opusListVideo}
                  accept=".mp4,.flv,.avi,.wmy,.mov,.webm,.mpeg4,.ts,.mpg,.rm,.rmvb,.mkv,.m4v"
                  beforeUpload={beforeUploadVideo}
                  maxCount={8}
                  // multiple
                  customRequest={async (i: any) => {
                    if (loadingVideo) {
                      return;
                    }
                    setLoadingVideo();
                    const unit = i.file.name.split(',');
                    // const file: any = await compressImgOrVideo(i.file);
                    // if (file === false) {
                    //   setLoadingVideo();
                    //   return;
                    // }
                    const url = await singleUploadObs(i.file, `anchorManagement/avatar/video/${i.file.uid}${unit[unit.length - 1]}`);
                    const thumbFile = await getImgUrl(url);
                    const thumbUrl = await singleUploadObs(thumbFile, `anchorManagement/avatar/video/${thumbFile.name}`);
                    setAddOpusList(url, 1, thumbUrl);
                    setLoadingVideo();
                  }}
                  onRemove={(values) => {
                    setDeleteOpusList(values.url, 1);
                  }}
                >
                  {opusListVideo && opusListVideo.length >= 8 ? null : (
                    <button
                      style={{
                        border: 0,
                        background: 'none',
                      }}
                      type="button"
                    >
                      {loadingVideo ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{ marginTop: 8 }}>{loadingVideo ? '上传中' : '添加视频'}</div>
                    </button>
                  )}
                </Upload>
                <span>支持 mp4,flv,avi,wmy,mov,webm,mpeg4,ts,mpg,rm,rmvb,mkv,m4v 格式，大小不超过 5G</span>
              </Col>
            </Row>
          </Card>
        </div>
        <SelectGroupModal store={selectGroupStore} />
      </FullModal>
    );
  }
}
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('仅支持上传JPG、PNG!');
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error('图片必须小于5MB!');
    return false;
  }
  return isJpgOrPng && isLt2M;
};
const beforeUploadVideo = (file: FileType) => {
  const isJpgOrPng = [
    'video/mp4',
    'video/x-flv',
    'video/avi',
    'video/x-ms-wmv',
    'video/quicktime',
    'video/webm',
    'video/mpeg',
    'video/MP2T',
    'video/mpg',
    'application/vnd.rn-realmedia',
    'application/vnd.rn-realmedia-vbr',
    'video/x-matroska',
    'video/x-m4v',
  ].includes(file.type);
  if (!isJpgOrPng) {
    message.error('仅支持上传 mp4,flv,avi,wmy,mov,webm,mpeg4,ts,mpg,rm,rmvb,mkv,m4v 格式!');
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 300;
  if (!isLt2M) {
    message.error('视频必须小于300MB!');
    return false;
  }
  return isJpgOrPng && isLt2M;
};
