import type Model from '../../model';
import type { BaseData } from '../../../../utils';
import { request } from '../../../../utils';
import type { FormInstance, UploadFile } from 'antd';
import { message } from 'antd';
import SelectGroupModel from '../selectGroup/model';
import { action, computed, observable, toJS } from 'mobx';
import { API } from '../../api';
import _ from 'lodash';
import moment from 'dayjs';
import getImgUrl from '../../../../utils/firstFrame';

interface IGroup {
  anchorGroupId: number | string;
  anchorGroupName: string;
  anchorGroupNickName: string;
  anchorId?: number | string;
}

interface IOpus {
  anchorId?: number;
  id?: number;
  worksType: string;
  worksUrl: string;
}

export default class AnchorEditModel {
  constructor(parentStore: Model) {
    this.parentStore = parentStore;
    this.selectGroupStore = new SelectGroupModel({ parent: this });
  }

  @observable public loading = false;

  @observable public loadingSave = false;

  @observable public loadingVideo = false;

  @action
  public setLoadingVideo = () => {
    this.loadingVideo = !this.loadingVideo;
  };

  @observable public selectGroupStore;

  @observable public visible = false;

  @observable public type = 'add';

  @observable public anchorId; //编辑id

  @observable public ref: FormInstance;

  @observable public saveLoading = false;

  @observable public groupInfo: IGroup[] = []; // 群组列表

  @action
  public setGroupInfo = (anchorGroupId: string | number) => {
    this.groupInfo = this.groupInfo.filter((item: any) => item.anchorGroupId !== anchorGroupId);
  };

  @observable public opusListImage: any[] = []; // 图片作品列表

  @observable public opusListVideo: any[] = []; // 视频作品列表

  @action
  public setAddOpusList = (url: string, type: 0 | 1, thumbUrl?: string) => {
    if (type === 0) {
      this.opusListImage = [
        ...this.opusListImage,
        {
          uid: _.uniqueId('opusListImage_'),
          status: 'done',
          url,
        },
      ];
      return;
    }
    this.opusListVideo = [
      ...this.opusListVideo,
      {
        uid: _.uniqueId('opusListVideo_'),
        status: 'done',
        url,
        thumbUrl,
      },
    ];
  };

  @action
  public setDeleteOpusList = (url: string, type: 0 | 1) => {
    if (type === 0) {
      this.opusListImage = this.opusListImage.filter((item: any) => item.url !== url);
      return;
    }
    this.opusListVideo = this.opusListVideo.filter((item: any) => item.url !== url);
  };

  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  // 打开弹窗
  @action public onShow = async (data?: any, type = 'add') => {
    this.visible = !this.visible;
    if (data) {
      this.type = type;
      this.anchorId = data.id;
      const req = await request<BaseData<any>>({
        method: 'GET',
        url: '/quan/biz/anchor/detail',
        params: { id: data.id },
      });
      const { name, children, birthday, sex, height, weight, seniority, mobile, wechatCode, skilledField, highestGmv, groupInfo } = req.data || {};
      this.ref.setFieldsValue({
        name,
        birthday: moment(birthday),
        sex: Number(sex),
        height,
        weight,
        seniority,
        mobile,
        wechatCode,
        skilledField,
        highestGmv,
      });
      this.groupInfo = groupInfo;
      this.opusListImage = (children || [])
        ?.filter((item) => item.worksType === '0')
        ?.map((item) => ({
          uid: _.uniqueId('opusListImage_'),
          status: 'done',
          url: item.worksUrl,
        }));
      this.opusListVideo = await Promise.all(
        (children || [])
          ?.filter((item) => item.worksType === '1')
          ?.map((item) => ({
            uid: _.uniqueId('opusListVideo_'),
            status: 'done',
            url: item.worksUrl,
            thumbUrl: item.coverUrl,
          })),
      );
    }
  };

  // 关闭弹窗
  @action public onCancel = () => {
    this.visible = false;
    this.ref?.resetFields();
    this.groupInfo = [];
    this.opusListImage = [];
    this.opusListVideo = [];
    this.type = 'add';
  };

  // 添加群组
  @action public addGroup = (group: any) => {
    if (!group) {
      return;
    }
    this.groupInfo.push(group);
  };

  // 保存主播信息
  @action public onSave = async () => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }

    if (this.opusListVideo.length + this.opusListImage.length > 6) {
      message.error('作品(视频+图片)最多上传6个!');
      return;
    }
    if (this.loadingSave) {
      return;
    }
    this.loadingSave = true;
    // 获取表单值
    const values = this.ref.getFieldsValue();

    const postParams = {
      ...values,
      groupInfo: this.groupInfo,
      children: [],
    };
    const children = [];
    this.opusListImage?.forEach((item) => {
      children.push({
        worksType: '0',
        worksUrl: item.url,
      });
    });
    this.opusListVideo?.forEach((item) => {
      children.push({
        worksType: '1',
        worksUrl: item.url,
        coverUrl: item.thumbUrl,
      });
    });
    postParams.children = children;
    if (this.type === 'edit') {
      postParams.id = this.anchorId;
    }
    console.log('🚀 ~ AdminEditorModel ~ onSave= ~ postParams:', postParams);
    const apiUrl = this.type === 'add' ? API.saveAnchor : API.editAnchor;
    request<{ code: number; msg?: string; data: any }>({
      url: apiUrl,
      method: 'POST',
      data: { ...postParams },
    })
      .then((result) => {
        message.success(result.msg || '操作成功');
        this.ref.resetFields();
        this.onCancel();
        this.parentStore?.pageStore?.grid?.onQuery();
      })
      .finally(() => {
        this.loadingSave = false;
      });
  };

  public parentStore: Model;
}
